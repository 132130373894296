import React, { useState } from "react";
import { MailOutlined, EyeOutlined, RightCircleFilled } from "@ant-design/icons";
import { Layout, Button, Checkbox, Form, Input, Row, Col, Image, message, Space } from "antd";
import { useHistory } from "react-router-dom";
import loginImage from "../assets/images/loginpage.png";
import backgroundImageLogin from "../assets/images/backgroundlogin.png";
import Logo from "../assets/images/Logo.svg";
import { useSelector, useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../redux/alertSlice";
import { ApifordataPost } from "../pages/Helper/ApiForGetData";
import { setFilterValue } from "../redux/filterValues";

const Login = (props) => {
  const filterValues = useSelector((state) => state.filterValue);
  const [forgetPasswordFlag, setForgetpasswordFlag] = useState(false);
  const [changePassword, setchangePassword] = useState(false);
  const [localemail, setLocalemail] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogin = async (values) => {
    const user = {
      email: values.email,
      password: values.password,
    };
    let response = await ApifordataPost("/login/", user);
    if (response.success == false) {
      message.error(response?.message);
      return;
    } else {
      localStorage.setItem("access", [response.data.access]);
      localStorage.setItem("country", response.data.country);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", response.data.name);
      localStorage.setItem("email", response.data.email);
      localStorage.setItem("roles", response.data.roles);
      localStorage.setItem("organization", response.data.organization);

      localStorage.setItem("TimePeriodPromo", "Monthly");
      // localStorage.setItem("TimescalesDefault",response.data.Timescales)
      // localStorage.setItem("TimescalesWeeklyDefault",response.data.TimescalesWeekly)
      dispatch(
        setFilterValue({
          ...filterValues,
          Region: [response.data.country == "UK" ? "UK" : "Ireland"],
          // TimeScale:[response.data.Timescales],
          // TimescalesWeekly:[response.data.TimescalesWeekly]
        })
      );
      dispatch(showLoading());
      setTimeout(() => {
        history.push("/home");
        dispatch(hideLoading());
      }, 1500);
    }
  };
  const handleForgetPassword = async (values) => {
    const playLoad = {
      email: values.email,
    };
    let response = await ApifordataPost("/password/forget-password-redirect", playLoad);

    if (response.data.success == false) {
      message.error(response.data.success.message);
      setForgetpasswordFlag(false);
      return;
    } else {
      setForgetpasswordFlag(false);
      setchangePassword(false);

      setLocalemail(values.email);
      message.success(response.message);
    }
  };

  return (
    <>
      <Layout
        style={{
          background: "#274361",
          minHeight: "100vh",
          overflow: "auto",
          backgroundImage: `url(${backgroundImageLogin})`,
          backgroundSize: "100vw",
          backgroundRepeat: "no-repeat",
        }}
        className='App'
      >
        <section className='vh-100'>
          <div className='container py-5 h-100'>
            <div className='row d-flex align-items-center justify-content-center h-100'>
              <div className='col-md-7 col-lg-5 col-xl-5 offset-xl-1'>
                <div className='m-5 p-2'>
                  <div className='mb-4'>
                    <Image src={Logo} preview={false} />
                  </div>
                  <div className='text-white'>
                    <span
                      className=' text-center  mb-2 mt-4'
                      style={{
                        fontSize: "34px",
                        fontWeight: "600",
                        lineHeight: "45px",
                      }}
                    >
                      Welcome to Foodservice Competitor Scanner
                    </span>
                  </div>
                  <div className='text-white mb-4 '>
                    <span
                      style={{
                        fontSize: "24px",
                        fontWeight: "400",
                        lineHeight: "45px",
                      }}
                    >
                      360 degrees fact based insight tool
                    </span>
                  </div>
                  <div className='text-white mb-4 mt-4'>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                    >
                      {forgetPasswordFlag == false && changePassword == false ? "Please enter your details here" : "Forget Password ?"}
                    </span>
                  </div>
                  <div className='mt-4'>
                    {!forgetPasswordFlag && !changePassword && (
                      <Form
                        name='basic'
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        style={{
                          maxWidth: "100%",
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        autoComplete='off'
                        className='ml-2'
                        onFinish={handleLogin}
                      >
                        <Form.Item
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                          ]}
                        >
                          <Input placeholder='Email' size='large' prefix={<MailOutlined />} />
                        </Form.Item>

                        <Form.Item
                          name='password'
                          rules={[
                            {
                              required: true,

                              message: "Please input your password!",
                            },
                          ]}
                        >
                          <Input.Password placeholder='Password' size='large' style={{ background: "white" }} prefix={<EyeOutlined />} />
                        </Form.Item>

                        <Row>
                          <Col span={12}>
                            <Form.Item name='remember'>
                              <Checkbox size='large' className='text-white foo'>
                                Remember me ?
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={12} className='text-center'>
                            <Form.Item>
                              <a
                                onClick={() => {
                                  setForgetpasswordFlag(true);
                                }}
                                className='p-1 text-white'
                              >
                                Forget password
                              </a>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Form.Item>
                          <Button
                            type='primary'
                            htmlType='submit'
                            block
                            style={{
                              background: "#1DB9DE",
                              borderRadius: "10px",
                              color: "white",
                            }}
                            size='large'
                          >
                            Login
                            <RightCircleFilled className='p-1' />
                          </Button>
                        </Form.Item>
                      </Form>
                    )}
                    {forgetPasswordFlag && !changePassword && (
                      <Form
                        name='basic'
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        style={{
                          maxWidth: "100%",
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        autoComplete='off'
                        className='ml-2'
                        onFinish={handleForgetPassword}
                      >
                        <Form.Item
                          name='email'
                          rules={[
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                          ]}
                        >
                          <Input placeholder='Email' size='large' prefix={<MailOutlined />} />
                        </Form.Item>

                        <Space>
                          <Button
                            type='primary'
                            htmlType='submit'
                            block
                            style={{
                              background: "#1DB9DE",
                              borderRadius: "10px",
                              color: "white",
                            }}
                            size='large'
                            className='m-1'
                          >
                            Submit
                          </Button>

                          <Button
                            type='primary'
                            block
                            style={{
                              background: "#1DB9DE",
                              borderRadius: "10px",
                              color: "white",
                            }}
                            size='large'
                            onClick={() => {
                              setForgetpasswordFlag(false);
                            }}
                            className='m-1'
                          >
                            Cancel
                          </Button>
                        </Space>
                      </Form>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-8 col-lg-7 col-xl-6'>
                <Image src={loginImage} className='img-fluid' alt='Phone image' preview={false} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default Login;
