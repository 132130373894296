import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Image, Space, Tooltip, Tag, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showFilterModal, hideFilterModal } from "../../redux/filterModal";
import ViewCustomized from "../../assets/images/icons/ViewCustomized.png";
import FilterModal from "../FilterModal";
import Bar from "./Bar";
import { useLocation } from "react-router-dom";
import { URL } from "../Helper/MainURL";
import PapaParse from "papaparse";
import { DownloadOutlined } from "@ant-design/icons";


const Trends = (props) => {
  const location=useLocation();
  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const filterValues = useSelector((state) => state.filterValue);
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [modalflag, setModalFlag] = useState(false);
  const [img, setimg] = useState("");
  const [ImageName, setImageName] = useState("");
  const [calApi, setCalApi] = useState(0);
  const [loader1, setLoader1] = useState(true);
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 1);

  const [switchFlag, setswitchFlag] = useState(false);
  const [sorter, setSorter] = useState({
    columnKey: "Item",
    order: "ascend",
  });
  const { City, TimescalesTrend, MarketSegment, CompetitiveSet, Category, ProteinType, Channel, PriceRange, Item, loading, Timescales, Offer } = filterValues;
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(1);
    getProductsData();
  }, [calApi]);
  useEffect(() => {
    getProductsData();
  }, [currentPage, sorter, activeTab, switchFlag]);
  const showModal = () => {
    dispatch(showFilterModal());
  };
  const getProductsData = async () => {
    setLoader1(true);

    let payLoad2 = {
      filters: {
        Timescales: filterValues.TimescalesTrend,
        Market_Segment: filterValues.MarketSegment,
        Competitive_Set: filterValues.CompetitiveSet,
        Category: filterValues.Category,
        Protein_Type: filterValues.ProteinType,
        Offer: filterValues.Offer,
      },
      line_dashboard: switchFlag == false ? 1 : 2,
      email: localStorage.getItem("email"),
    };

    const randomParam = Math.random().toString(36).substring(7);

    await fetch(`${URL}/product-trend/?${randomParam}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payLoad2),
    })
      .then(function (response) {
        response.json().then(function (data) {
          setProductData(Array.isArray(data?.data) ? data.data : []);
          setTotalPage(data?.total_count);
          setTimeout(() => {
            setLoader1(false);
          }, 3000);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };

  const onChangeSwitch = (checked) => {
    setswitchFlag(checked);
  };
  const getDownloadData = (data) => {
    // setloadingExport(true);
    downloadcsv(data);
    console.log("downloadcsv::::::::",downloadcsv)
  };
  const downloadcsv = (data) => {
    console.log("data-----",data)
    // return
      let LastLine1={BRANDNAME:"Copyright 2024. Meaningful Vision . All Rights Reserved. Proprietary and Confidential"}
      data.push(LastLine1)
      let LastLine2={BRANDNAME:"Property of Meaningful Vision  and it's Affiliates. Licensed for Use by Meaningful Vision  Clients Only."}
      data.push(LastLine2)
    
   
    // setloadingExport(false);
    var csv = PapaParse.unparse(data);
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download",  "Product-trends");
    tempLink.click();
  };

  return (
    <>
      <Row className='mb-2'>
        <Col span={8} className='p-1'>
          <span className='bg-white text-dark p-2 rounded'>
            <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
              Products
            </span>{" "}
            <span style={{ color: "#1DB9DE" }} className='fw-bold'>
              {" "}
              |
            </span>{" "}
            <span className='page-header-table' style={{ fontFamily: "Quicksand" }}>
              Table
            </span>
          </span>
        </Col>
        <Col span={12} className='text-center'></Col>
        <Col span={4} className='p-1 text-center'>
          {" "}
          <Button onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
            Filters
          </Button>
          {" "}
          <Button
                block
                className='mb-4 export-button '
                icon={<DownloadOutlined />}
                size={"large"}
                // loading={loadingExport}
                onClick={() => {
                 getDownloadData(productData);
                }}
              ></Button>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Space size={[0, "small"]} wrap>
          <Tooltip placement='bottomLeft' title={TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : " All"}>
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Timescales:
              {TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : "Not selected"}{" "}
            </Tag>
          </Tooltip>

          <Tooltip
            placement='bottomLeft'
            title={
              MarketSegment && MarketSegment.length > 0
                ? MarketSegment.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Segment:
              {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              CompetitiveSet && CompetitiveSet.length > 0
                ? CompetitiveSet.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Competitive:
              {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          {activeTab != 2 && (
            <Tooltip
              placement='bottomLeft'
              title={
                Category && Category.length > 0
                  ? Category.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Category:
                {Category && Category.length > 0 ? `${Category[0]}  ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          {activeTab != 2 && (
            <Tooltip
              placement='bottomLeft'
              title={
                ProteinType && ProteinType.length > 0
                  ? ProteinType.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                ProteinType:
                {ProteinType && ProteinType.length > 0
                  ? `${ProteinType[0]} 
             
              `
                  : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          <Tooltip
            placement='bottomLeft'
            title={
              Offer && Offer.length > 0
                ? Offer.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Offer:
              {Offer && Offer.length > 0 ? `${Offer[0] == 1 ? " LTO" : " NOT LTO"} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
        </Space>
      </Row>

      {activeTab == 1 && props && (
        <div className='text-center mb-3 mt-3'>
          <span className='p-2'>{"Active"}</span>

          <Switch onChange={onChangeSwitch} className='square-switch' />
          <span className='p-2'>{"New"}</span>
        </div>
      )}

      {activeTab == 1 && <Bar productData={productData} productTrends={"trends"}  />}

      <Modal
        open={modalflag}
        justifyContent='center'
        destroyOnClose
        onCancel={(e) => {
          setModalFlag(false);
        }}
        footer={false}
        size='large'
        width={600}
        height={600}
        title={<div className='text-left fs-6'>{ImageName}</div>}
      >
        <div style={{ display: "flex", justifyContent: "center", minHeight: "300px" }}>
          <img src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/product/${img}`} width='300' height='300px' />
        </div>{" "}
      </Modal>
      <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} setCalApi={setCalApi} calApi={calApi} name={"Product"} activeTabProductTrends={activeTab} dashboard={"Products"}/>
    </>
  );
};

export default Trends;
