import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { setFilterValue } from "../../redux/filterValues";

function convertDateFormat(dateString) {
  if (!dateString) {
    dateString = ["dateString"];
  }

  const [monthStr, yearStr] = dateString && dateString != undefined ? dateString.split("-") : ["Apr-24"].split("-");

  const monthIndex = new Date(Date.parse(monthStr + " 1, " + yearStr)).getMonth();

  const daysInMonth = new Date(parseInt(yearStr), monthIndex + 1, 0).getDate();

  const month = (monthIndex + 1).toString().padStart(2, "0");

  const startDate = `20${yearStr}-${month}-01`;
  const endDate = `20${yearStr}-${month}-${daysInMonth}`;

  return [startDate, endDate];
}
function addDays(dateString, days) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + days);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return [dateString,`${year}-${month}-${day}`];
}




const ColumnWithData = (props) => {
  const { setActiveTab, filterValues1, calApi } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState([]);
  const [categories, setCategories] = useState([]);
  const handleBarClick = (event, chartContext, config) => {
    const category = config.w.config.xaxis.categories[config.dataPointIndex];

    let Ts = localStorage.getItem("Timescales"),
      Ms = localStorage.getItem("MarketSegment"),
      Cs = localStorage.getItem("CompetitiveSet"),
      Cat = localStorage.getItem("Category"),
      Cha = localStorage.getItem("SourceType"),
      Pt1 = localStorage.getItem("PromoType"),
      Pt2 = localStorage.getItem("PromoType2"),
      TPromo=localStorage.getItem("TimePeriodPromo"),
      TPromoWeekly=localStorage.getItem("TimescalesWeekly");
    var temp = new Array();
    if (Ts) {
      var dateTrend = convertDateFormat(Ts);
    } else {
      var dateTrend = convertDateFormat(...filterValues1.Timescales);
    }

    if (Ms) {
      var temp = new Array();
      temp = Ms.split(",");
    }
    if(TPromo=="Weekly")
      {
var newDate = addDays(TPromoWeekly, 6);

      }

    dispatch(
      setFilterValue({
        MarketSegment: [...temp],
        TimescalesTrend:TPromo=="Weekly"?[...newDate]:[...dateTrend],
        CompetitiveSet: [category],
        Category: Cat ? [Cat] : [],
        SourceType: Cha ? [Cha] : [],
        PromoType: Pt1 ? [Pt1] : [],
        PromoType2: Pt2 ? [Pt2] : [],
        City:["All"]
      })
    );
    setActiveTab(1);
  };

  useEffect(() => {
    let temData = [];
    let tempCategory = [];

    if (props?.promoData?.data) {
      props.promoData.data.forEach((e) => {
        if (e && e.BrandName) {
          temData.push(e.CountPromo);
          tempCategory.push(e.BrandName);
        }
      });
    }

    setCategories(tempCategory);
    setData1(temData);
    setTimeout(()=>{
      setLoading(false);
    },3000)

    // setLoading(false);
  }, [props, calApi]);

  const options = {
    chart: {
      height: 550,
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
        },
        export: {
          png: {
            filename: "Meaningful Vision",
          },
        },
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          handleBarClick(event, chartContext, config);
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
        columnWidth: categories.length > 10 ? "80%" : "20%",
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#1db9de", "#2ec551", "#f39c12", "#e74c3c"],
    dataLabels: {
      enabled: true,
      formatter: (val) => val,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: categories,
      position: "bottom",
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: (val) => val,
      },
    },
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Spin></Spin>
        </div>
      )}
      <ReactApexChart
        options={options}
        series={[
          {
            name: "Number of active promotions",
            data: data1,
          },
        ]}
        type='bar'
        height={550}
      />
    </div>
  );
};

export default ColumnWithData;
