import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Input, Row, Col, Button, Space, message } from "antd";
import { URL } from "../Helper/MainURL";

const BenchMark = (props) => {
  const { openModal3, handleCloseModal3, initialState, brands, getUserData } = props;
  const [products, setProducts] = useState([]);
  const [form] = Form.useForm();
  const [populate, setPopulate] = useState("");

  var country = localStorage.getItem("country") === "UK" ? "UK" : "Ireland";
  console.log("props:::::::::",props,initialState)

  const onFinish = async (values) => {
    const { organization, Items: selectedProducts } = values;

    let payload = {
      organization: organization,
      items: selectedProducts,
    };

    try {
      const apiEndpoint = initialState?.length == 0 ? `${URL}/benchmark/add-benchmark` : `${URL}/benchmark/edit-benchmark`;

      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      message.success(initialState?.length === 0 ? "Benchmark created successfully!" : "Benchmark updated successfully!");
      getUserData();
      handleCloseModal3();
    } catch (error) {
      console.error("Error:", error);
      message.error("An error occurred while saving the benchmark.");
    }
  };

  const getDropdown = async (organization) => {
    let payload = {
      organization: organization,
      country: country,
    };

    try {
      const response = await fetch(`${URL}/benchmark/get-benchmark-items`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setProducts(data.filters || []);
  
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  

  useEffect(() => {
    if (initialState?.Organization) {
      console.log("initialState :::", initialState)
      // Edit Benchmark: Populate form with existing data
      form.setFieldsValue({
        organization: initialState.Organization,
        Items: initialState.Items || [],
      });
      getDropdown(initialState.Organization); // Load products for the selected organization
    } else {
      // Create Benchmark: Reset form to clear all fields
      form.resetFields();
      setProducts([]); // Clear products list
    }
  }, [initialState, form]);

  
  return (
    <div>
      <Modal
        open={openModal3}
        title={initialState?.length == 0 ? "Create Benchmark" : "Edit Benchmark"}
        layout='vertical'
        autoComplete='off'
        footer={false}
        width={550}
        height={600}
        maskClosable={false}
        destroyOnClose
        onCancel={handleCloseModal3}
      >
        <Form
          onFinish={onFinish}
          form={form}
          initialValues={initialState.length && initialState.Organization && initialState.Items && initialState?.length != 0?{
            organization: initialState.Organization,
            Items: initialState.Items,
          } : undefined}
        >
          <Row>
            <Col span={12}>
              <Form.Item label={"Organization"} name='organization' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: "Please enter organization" }]}>
                <Select
                  style={{
                    width: 450,
                  }}
                  allowClear
                  placeholder={initialState?.length === 0 ? "Click to select" : ""}
                  size='large'
                  options={brands}
                  onChange={(value) => {
                    getDropdown(value);
                    setPopulate(value)
                  }}
                  disabled={initialState?.length == 0 ? false : true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label={"Product"} name='Items' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: "Please enter Products name" }]}>
                <Select
                  mode='multiple'
                  placeholder='Select items'
                  size='large'
                  style={{ width: 450 }}
                  options={products.map((product) => ({
                    value: product.value,
                  }))}
                  dropdownStyle={{ maxHeight: 200, overflowY: "auto" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ alignItems: "center" }}>
                <Space>
                  <Button htmlType='submit'>{initialState?.length == 0 ? "Create" : "Save"}</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default BenchMark;
