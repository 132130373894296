import React, { useEffect, useState } from "react";
import { Space, Table, Row, Col, Button, Image, Tabs, Tag, Radio, Pagination, Tooltip, notification, message, Select, Spin } from "antd";
import ViewCustomized from "../../assets/images/icons/ViewCustomized.png";
import TableIcon from "../../assets/images/icons/Table.png";
import Line from "../../assets/images/icons/Line.png";
import { DownloadOutlined } from "@ant-design/icons";
import FilterModal from "../FilterModal";
import { useDispatch, useSelector } from "react-redux";
import { showFilterModal, hideFilterModal } from "../../redux/filterModal";
import { setFilterValue } from "../../redux/filterValues";
import PapaParse from "papaparse";
import { useLocation } from "react-router-dom";
import { URL } from "../Helper/MainURL";

import LineChart from "../Visits/LineChart";
const Label = ["", "Store count", "Openings", "Closures"];

const columns = [
  {
    title: "Chain",
    dataIndex: "Brand",
    key: "Brand",
    sorter: true,
    fixed: true,
    render: (text) => <a>{text}</a>,
  },
  // {
  //   title: "Region",
  //   dataIndex: "region",
  //   key: "region",
  //   sorter: true,
  //   fixed: true,
  //   render: (text) => <a>{text}</a>,
  // },
  // {
  //   title: "City",
  //   dataIndex: "city",
  //   key: "region",
  //   sorter: true,
  //   fixed: true,
  //   render: (text) => <a>{text}</a>,
  // },
  {
    title: "Category",
    dataIndex: "Category",
    key: "Category",
    sorter: true,
    render: (text) => <a>{text}</a>,
  },
];
let priceCount = 0;


function OpeningsTrande(props) {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 1);
  const [data, setData] = useState([]);
  const [LineChartData, setLineChartData] = useState([]);
  const [months, setMonths] = useState([]);
  const [monthschart, setMonthschart] = useState([]);
  const [mode, setMode] = useState("No Comparison");
  const [defaultPage, setDefaultPage] = useState("Table");
  const [cityTemp, setcityTemp] = useState(['All']);
  const handleModeChange = (e) => {
    setSorter({
      columnKey: "Competitive_Set",
      order: "ascend",
    });
    setSorter1({
      columnKey: "Competitive_Set",
      order: "ascend",
    });
    setMode(e.target.value);
    setCurrentPageTrend(1);
  };
  const { loader } = useSelector((state) => state.loader);
  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const filterValues = useSelector((state) => state.filterValue);
  const [calApi, setCalApi] = useState(0);
  const [currentPage1, setCurrentPageTrend] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [loadingExport, setloadingExport] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [summations, setsummations] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const { TimescalesTrend, MarketSegment, CompetitiveSet, TimePeriod, Region, DayTime, City, TimePeriodOpenings, RegionOpenings ,region,cityRegion} = filterValues;
  const [sorter, setSorter] = useState({
    columnKey: "Competitive_Set",
    order: "ascend",
  });
  const [sorter1, setSorter1] = useState({
    columnKey: "Competitive_Set",
    order: "ascend",
  });
  const dispatch = useDispatch();
  const showModal = () => {
    dispatch(showFilterModal());
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };
  useEffect(() => {
    priceCount= 0
  }, []);
  useEffect(() => {
    setCurrentPageTrend(1);
    getData(filterValues);
  }, [calApi]);
  useEffect(
    (e) => {
      getData(filterValues);
    },
    [activeTab, mode, currentPage1, sorter, sorter1, defaultPage]
  );

  const getData = async (data) => {
    setloader3(true);
    let payload = {
      page_number: currentPage1,
      filters: {
        TimeScale: data?.TimescalesTrend,
        TimePeriod: data?.TimePeriodOpenings,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Region: data?.region,
        cityRegion:  data?.cityRegion,
        // region: data?.region,cityTemp && cityTemp.length && cityTemp[0] == 'All'? cityTemp  :

      },
      sort_column: activeTab == 1 ? sorter.columnKey : sorter1.columnKey,
      sort_type: activeTab == 1 ? (sorter.order == "ascend" ? "ASC" : "DESC") : sorter1.order == "ascend" ? "ASC" : "DESC",
      comparison_type: [mode],
      data_type: defaultPage == "Table" ? 1 : 2,
      email: localStorage.getItem("email"),
      country: localStorage.getItem("country"),
    };
    // setcityTemp(data?.City)
    // console.log("payload::::::outlet",payload)

    const randomParam = Math.random().toString(36).substring(7);

    await fetch(`${URL}/openings/${activeTab == 1 ? "total-outlets" : activeTab == 2 ? "new-outlets" : "closed-outlets"}?${randomParam}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      cache: "default",

      body: JSON.stringify(payload),
    })
      .then(function (response) {
        response.json().then(function (data) {
          // console.log('::::::::summations data',data)
          if (!data?.Line_chart_data) {
            return
          }
          if (data?.Line_chart_data) {
            data.Line_chart_data.forEach(function (obj) {
              if (obj.data) {
                obj.data = obj.data.map(function (item) {
                  if (typeof item == "number") return;
               
                  return item.replace(/,/g, "");
                });
              }
            });
          } else {
            console.error("Line_chart_data does not exist.");
          }
          
          setLineChartData(data?.Line_chart_data);

          const summations = { ...data?.total };

          setsummations(summations);
          priceCount++

          setMonths(data?.columns);
          setMonthschart(data?.columns);
          setTotalPage(data?.total_count);
          // if (activeTab == 1 || activeTab == "1") {
          // console.log('::::::::summations',summations)
            data.data.push(summations);
          // }
          setData(data?.data);

          const timer = setTimeout(() => {
            setloader3(false);
          }, 3000);
          return () => clearTimeout(timer);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const onChangeTab = (e) => {
    setActiveTab(e);
    setCurrentPageTrend(1);
  };

  for (let i = 0; i < months?.length; i++) {
    let width = 50;

    if (months[i] == "Brand") {
      width = 200;
    }
    const newColumn = {
      title: months[i],
      dataIndex: months[i],
      key: months[i],
      width: 200,
      fixed: "left",
      sorter: true,
    };
    columns.push(newColumn);
  }

  columns.push({
    title: "Chain",
    dataIndex: "Brand",
    key: "Brand",
    sorter: true,
    fixed: true,
    width: 100,

    render: (text) => <a>{text}</a>,
  });
  columns.push({
    title: "Category",
    dataIndex: "Category",
    key: "Category",
    sorter: true,
    width: 100,
    render: (text) => <a>{text}</a>,
  });
  const handleClose = (data) => {
    dispatch(
      setFilterValue({
        TimescalesTrend: data == 1 ? [] : filterValues.TimescalesTrend,
        MarketSegment: data == 2 ? [] : filterValues.MarketSegment,
        CompetitiveSet: data == 3 ? [] : filterValues.CompetitiveSet,
        Category: data == 4 ? [] : filterValues.Category,
        ProteinType: data == 5 ? [] : filterValues.ProteinType,
        Channel: data == 6 ? [] : filterValues.Channel,

        City: data == 7 ? [] : filterValues.City,
        Item: data == 8 ? [] : filterValues.Item,
        ProductSize: data == 9 ? [] : filterValues.ProductSize,
        loading: true,
      })
    );
    setCalApi(calApi + 1);
  };
  const getDownloadData = (data) => {
    // openNotification('topRight');
    setloadingExport(true);
    downloadcsv(data);
  };
  const downloadcsv = (data) => {
   
    setloadingExport(false);
    let LastLine1={BrandName:"Copyright 2024.   Meaningful Vision. All Rights Reserved. Proprietary and Confidential"}
    data.push(LastLine1)
    let LastLine2={BrandName:"Property of Meaningful Vision and it's Affiliates. Licensed for Use by Meaningful Vision Clients Only."}
   
    data.push(LastLine2)
    var csv = PapaParse.unparse(data);
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${Label[activeTab]}.csv`);
    tempLink.click();
  };
  const openNotification = (placement) => {
    api.info({
      message: `Downloading`,
      description: "While your file is being retrieved, feel free to continue using the tool.",
      placement,
    });
  };
  const tableFooter = () => (
    <div className='table-footer'>
      <strong>Total:</strong> 3 items <strong>Total:</strong> 3 items
    </div>
  );
  console.log('cityTemp :::::::',cityTemp)
  return (
    <>
      {contextHolder}
      <div className='container-fluid'>
        <Row>
          <Col span={8} className='p-1'>
            <span className='bg-white text-dark p-2 rounded'>
              <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
                {Label[activeTab]}
              </span>{" "}
              <span style={{ color: "#1DB9DE" }} className='fw-bold'>
                {" "}
                |
              </span>{" "}
              <span className='page-header-table' style={{ fontFamily: "Quicksand" }}>
                Table
              </span>
            </span>
          </Col>
          <Col span={8} className='text-left'>
            <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8 }} size='large' defaultValue={"No Comparison"}>
              <Radio.Button value={"No Comparison"}>No Comparison</Radio.Button>
              <Radio.Button value={"Vs Last Period"}>vs Last Period</Radio.Button>
              <Radio.Button value={"Vs Last Year"}>vs Last Year</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={3}>
          {/* <Select size={"large"}
                defaultValue='Bookmark'
                className='mb-4'
                style={{ width: 190 }}   options={[{label:"Bookmark1",value:"Bookmark1"},{label:"Bookmark2",value:"Bookmark2"}]} /> */}
                </Col>

          <Col span={5} className=' text-center'>
            <Space>
              <Select
                size={"large"}
                defaultValue='Table'
                className='mb-4'
                style={{ width: 150 }}
                onChange={(e) => {
                  setDefaultPage(e);
                }}
                options={[
                  {
                    value: "Table",
                    label: (
                      <div className='fs-6 bolder'>
                        <Image src={TableIcon} preview={false} style={{ background: "white" }} /> <span className='px-2'> Table</span>
                      </div>
                    ),
                  },

                  {
                    value: "Line",
                    label: (
                      <>
                        <Image src={Line} preview={false} style={{ background: "white" }} /> <span className='px-2'> Line </span>
                      </>
                    ),
                  },
                ]}
              />

              <Button block onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
                Filters
              </Button>
              <Button
                block
                className='mb-4 export-button '
                icon={<DownloadOutlined />}
                size={"large"}
                loading={loadingExport}
                onClick={() => {
                  getDownloadData(data);
                }}
              ></Button>
            </Space>
          </Col>
        </Row>
        <Row>
          <Space size={[0, "small"]} wrap>
            <Tooltip
              placement='bottomLeft'
              title={
                TimePeriodOpenings && TimePeriodOpenings.length > 0
                  ? TimePeriodOpenings.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Time Period:
                {TimePeriodOpenings && TimePeriodOpenings.length > 0 ? `${TimePeriodOpenings[0]} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip placement='bottomLeft' title={TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : " All"}>
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Timescales:
                {TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                MarketSegment && MarketSegment.length > 0
                  ? MarketSegment.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Segment:
                {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                CompetitiveSet && CompetitiveSet.length > 0
                  ? CompetitiveSet.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Competitive:
                {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                region && region.length > 0
                  ? region.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Region:
                {region && region.length > 0 ? `${region[0]}  ${region?.length > 1 ? region.length : " " } ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                cityRegion && cityRegion.length > 0 && priceCount > 2
                  ? cityRegion.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-css' style={{ borderRadius: "10px" }}>
                City:
                {cityRegion && cityRegion.length > 0 && priceCount > 2 ? `${cityRegion[0]}  ${cityRegion?.length > 1 ? cityRegion.length : " " } ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          </Space>
        </Row>

        {defaultPage === "Table" ? (
  <Tabs
    defaultActiveKey={activeTab}
    className='activetabcolor'
    onChange={onChangeTab}
    items={new Array(3).fill(null).map((_, i) => {
      return {
        label: <>{`${Label[i + 1]}`} </>,
        key: i + 1,
        children: (
          <>
     {months && (
  <>
    <Table
      columns={months.map((e, i) => {
        let width = 110,
            fixed = false;

        // Set width and fixed properties based on the column
        if (e === "BrandName") {
          width = 200;
          fixed = 'left';
        } else if (e === "Region2" || e === "City") {
          width = 150;
          fixed = 'left';
        }

        return {
          title: e === "BrandName" ? "Chain" : e === "Region2" ? "Region" : e,
          dataIndex: e,
          key: e,
          width: width,
          fixed: fixed,
          sorter: (a, b) => {
            // Skip sorting for rows with "Total"
            if (
              ["Total", "total", "TOTAL"].includes(a.BrandName) ||
              ["Total", "total", "TOTAL"].includes(b.BrandName)
            ) {
              return 0;
            }

            // Determine if the columns are strings or numbers and sort accordingly
            const isStringComparison = typeof a[e] === "string" && typeof b[e] === "string";
            const isNumberComparison = typeof a[e] === "number" && typeof b[e] === "number";

            if (isStringComparison) {
              return a[e]?.localeCompare(b[e]);
            } else if (isNumberComparison) {
              return a[e] - b[e];
            } else {
              return 0;
            }
          },
          render: (text, record) => {
            // Handle empty or invalid text cases
            if (!text || text === "NAN" || text === "-" || text === "") {
              return <span style={{ fontWeight: 700 }}>-</span>;
            } else {
              // Check for specific columns or active tabs 2 or 3
              if (
                (mode === "No Comparison" ||
                e === "BrandName" ||
                e === "Region2" ||
                e === "City") || (activeTab === "2" || activeTab === "3")
              ) {
                // Handle TOTAL rows
                if (["TOTAL", "Total"].includes(record.BrandName)) {
                  return <span className='fw-bold'>{text.toLocaleString()}</span>;
                }
                return <>{text}</>; // Return the text without %
              } else {
                // Active Tab 1
                if (activeTab === "1") {
                  // Check if mode is "Vs Last Period" or "Vs Last Year"
                  if (mode === "Vs Last Period" || mode === "Vs Last Year") {
                    return <span className='fw-bold'>{text + "%"}</span>; // Add % for these modes
                  }
                  // If the mode is not "Vs Last Period" or "Vs Last Year"
                  return <>{text}</>; // Return text without %
                }
                // Default case (if activeTab is not 1)
                return <>{text }{activeTab ==1 ? '%' : ''}</>; // Return text without %
              }
            }
          },
        };
      })}
      dataSource={loader3 ? [] : data}
      loading={loader3}
      pagination={false}
      scroll={{
        x: 920,
        y: "45vh",
      }}
      bordered
    />
  </>
)}


          </>
        ),
      };
    })}
  />
) :
 (
          <Tabs
            defaultActiveKey={activeTab}
            className='activetabcolor'
            onChange={onChangeTab}
            items={new Array(3).fill(null).map((_, i) => {
              return {
                label: <>{`${Label[i + 1]}`} </>,
                key: i + 1,
                children: <>{months && <>{loader3 ? <Spin fullscreen /> : <LineChart data={LineChartData} months={monthschart} activeTab={activeTab} mode={mode} />}</>}</>,
              };
            })}
          />
        )}
      </div>

      <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} closeIcon={null} setCalApi={setCalApi} calApi={calApi} name={"OpeningTrends"} dashboard={"Openings"} />
    </>
  );
}

export default OpeningsTrande;
