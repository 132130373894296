import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined, BellOutlined, MoonFilled, SunFilled } from "@ant-design/icons";
import Homeicon from "../../assets/images/icons/homeicon.png";
import Dashboardicon from "../../assets/images/icons/dashboardicon.png";
import Mydashboardicon from "../../assets/images/icons/mydashboardicon.png";
import LogoutIcon from "../../assets/images/icons/logout.png";
import ManegeUser from "../../assets/images/icons/manageuser.png";

import { Layout, Menu, Button, theme, Image, Dropdown, Row, Col, Switch } from "antd";
import { Link, useHistory } from "react-router-dom";
import { showLight, showDark } from "../../redux/cutomeTheme";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../redux/alertSlice";
import { ApifordataPost } from "../../pages/Helper/ApiForGetData";

const { Header, Sider, Content } = Layout;

const Sidenav = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation(); // React Hook
  let newString = location.pathname.replace("/", "");

  const [collapsed, setCollapsed] = useState(false);
  const [activeKey, setActiveKey] = useState(["2"]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const changeTheme = (e) => {
    if (e) {
      dispatch(showDark());
    } else {
      dispatch(showLight());
    }
  };
  const handleUsermanagement = () => {
    history.push("/user-management");
  };
  const handleUserProfile = () => {
    history.push("/profile");
  };
  const Logout = async () => {
    dispatch(showLoading());
    let token = {
      token: localStorage.getItem("token"),
    };
    let data = await ApifordataPost("/logout/", token);
    if (data.message == "User logged out successfully." && data.success) {
      localStorage.removeItem("country");
      localStorage.clear();

      history.push("/");
      dispatch(showLight());
      dispatch(hideLoading());
    } else {
      localStorage.removeItem("country");
      localStorage.clear();

      history.push("/");
      dispatch(showLight());
      dispatch(hideLoading());
    }
  };
  let roles = localStorage.getItem("roles");
  const items = [
    {
      label: (
        <>
          {" "}
          <Image src={ManegeUser} className='w-100 p-2' width={34} preview={false} />
          <a style={{ fontFamily: "Quicksand", fontWeight: "600" }} onClick={handleUsermanagement}>
            Manage Users
          </a>
        </>
      ),
      key: "user-management",
      hidden: roles && roles == "Super Admin" ? false : true,
    },
    {
      label: (
        <>
          {" "}
          <Image src={"https://api.dicebear.com/7.x/miniavs/svg?seed=1"} className='w-100 p-2' width={34} preview={false} />
          <a style={{ fontFamily: "Quicksand", fontWeight: "600" }} onClick={handleUserProfile}>
            Profile
          </a>
        </>
      ),
      key: "profile",
    },

    {
      label: (
        <>
          <Image src={LogoutIcon} className='w-100 p-2 ' width={34} preview={false} />
          <a onClick={Logout} style={{ fontFamily: "Quicksand", fontWeight: "600" }}>
            Logout
          </a>
        </>
      ),
      key: "Logout",
    },
  ];

  const items1 = [
    {
      key: "Home",
      icon: <Image src={Homeicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
      label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Home</a>,
      href: "/home",
      sequence: 1,
    },
    {
      key: "Dashboard",
      icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
      label: (
        <Link
          to={"/dashboard"}
          className='fs-6 '
          style={{
            color: "white",
            borderRadius: "12px",
            textDecoration: "none",
          }}
        >
          <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}>Dashboard</a>
        </Link>
      ),
      href: "/dashboard",
      sequence: 2,
      children: [
        {
          key: "Visits",
          icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
          label: (
            <Link
              to={"/Visits"}
              className='fs-6'
              style={{
                color: "white",
                borderRadius: "12px",
                textDecoration: "none",
              }}
            >
              <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Visits</a>
            </Link>
          ),
          href: "/Visits",
          sequence: 3,
          children: [
            {
              key: "traffic-trends",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Trends</a>,
              href: "/traffic-trends",
              sequence: 22,
            },
            {
              key: "traffic-snapshot",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Snapshot</a>,
              href: "/traffic-snapshot",
              sequence: 21,
            },
          ],
        },
        {
          key: "Prices",
          icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
          label: (
            <Link
              to={"/Prices"}
              className='fs-6'
              style={{
                color: "white",
                borderRadius: "12px",
                textDecoration: "none",
              }}
            >
              <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Prices</a>
            </Link>
          ),
          href: "/Prices",
          sequence: 4,
          children: [
            {
              key: "prices-trends",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Trends</a>,
              href: "/prices-trends",
              sequence: 5,
            },
            {
              key: "prices-snapshot",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Snapshot</a>,
              href: "/prices-snapshot",
              sequence: 6,
            },
          ],
        },
        {
          key: "Products",
          icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
          label: (
            <Link
              to={"/Products"}
              className='fs-6'
              style={{
                color: "white",
                borderRadius: "12px",
                textDecoration: "none",
              }}
            >
              {" "}
              <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Products</a>
            </Link>
          ),
          href: "/Products",
          sequence: 7,
          children: [
            {
              key: "products-trends",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Trends</a>,
              href: "/products-trends",
              sequence: 13,
            },
            {
              key: "products-snapshot",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Snapshot</a>,
              href: "/products-snapshot",
              sequence: 14,
            },
          ],
        },
        {
          key: "Promotions",
          icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
          label: (
            <Link
              to={"/Promotions"}
              className='fs-6'
              style={{
                color: "white",
                borderRadius: "12px",
                textDecoration: "none",
              }}
            >
              <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Promotions</a>
            </Link>
          ),
          href: "/Promotions",
          sequence: 8,
          children: [
            {
              key: "Promotions-trends",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Trends</a>,
              href: "/Promotions-trends",

              sequence: 22,
            },
            {
              key: "Promotions-snapshot",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Snapshot</a>,
              href: "/Promotions-snapshot",
              sequence: 21,
            },
          ],
        },
        {
          key: "L4LAnalytics",
          icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
          label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> L4L Analytics</a>,
          href: "/L4LAnalytics",
          sequence: 9,
        },
        {
          key: "Openings",
          icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
          label: (
            <Link
              to={"/Openings"}
              className='fs-6'
              style={{
                color: "white",
                borderRadius: "12px",
                textDecoration: "none",
              }}
            >
              <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Store count</a>
            </Link>
          ),
          href: "/Openings",
          sequence: 10,
          children: [
            {
              key: "openings-trends",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Trends</a>,
              href: "/openings-trends",

              sequence: 23,
            },
            {
              key: "openings-snapshot",
              icon: <Image src={Dashboardicon} className='w-50' width={collapsed ? 50 : 40} preview={false} />,
              label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> Snapshot</a>,
              href: "/openings-snapshot",
              sequence: 24,
            },
          ],
        },
      ],
    },

    {
      key: "My-dashboard",
      icon: <Image src={Mydashboardicon} className='w-50' width={collapsed ? 50 : 40} />,
      label: <a style={{ fontFamily: "Quicksand", fontWeight: "600" }}> My Dashboard</a>,
      href: "/My-dashboard",
      sequence: 12,
    },
  ];

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className='demo-logo-vertical'>
          <img src={collapsed ? "static/logo-mini.png" : "static/image1.png"} height={"auto"} width={collapsed ? 80 : 200} className='m-2 p-4' />
        </div>

        <Menu
          theme='dark'
          mode='inline'
          selectedKeys={[...activeKey]}
          defaultOpenKeys={[...activeKey]}
          onSelect={(k) => {
            setActiveKey(k.key);
          }}
        >
          {items1
            .sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
            .map((item) => {
              if (item.children) {
                return (
                  <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                    {item.children.map((childItem) => {
                      if (childItem.children) {
                        return (
                          <Menu.SubMenu key={childItem.key} title={childItem.label}>
                            {childItem.children.map((grandChildItem) => (
                              <Menu.Item
                                key={grandChildItem.key}
                                style={{
                                  color: "white",
                                  borderRadius: "12px",
                                  fontSize: "16px",
                                }}
                              >
                                <Link to={grandChildItem.href} style={{ textDecoration: "none" }}>
                                  <span className=' text-bold text-white fs-7'>{grandChildItem.label}</span>
                                </Link>
                              </Menu.Item>
                            ))}
                          </Menu.SubMenu>
                        );
                      } else {
                        return (
                          <Menu.Item
                            key={childItem.key}
                            style={{
                              color: "white",
                              borderRadius: "12px",
                              fontSize: "16px",
                            }}
                          >
                            <Link to={childItem.href} style={{ textDecoration: "none" }}>
                              <span className=' text-bold text-white fs-7'>{childItem.label}</span>
                            </Link>
                          </Menu.Item>
                        );
                      }
                    })}
                  </Menu.SubMenu>
                );
              } else {
                return (
                  <Menu.Item
                    key={item.key}
                    style={{
                      color: "white",
                      borderRadius: "12px",
                      fontSize: "16px",
                    }}
                    icon={item.icon}
                  >
                    <Link to={item.href} style={{ textDecoration: "none" }}>
                      <span className=' text-bold text-white fs-7'>{item.label}</span>
                    </Link>
                  </Menu.Item>
                );
              }
            })}
        </Menu>
        <div style={{ position: "absolute", bottom: 50, left: 20, width: "100%" }}>
          <Switch
            style={{ minHeight: "20px", minWidth: "50px" }}
            height={100}
            width={100}
            checkedChildren={
              <>
                <MoonFilled style={{ minHeight: "10px" }} />
              </>
            }
            unCheckedChildren={
              <>
                <SunFilled style={{ minHeight: "10px" }} />
              </>
            }
            onChange={changeTheme}
          />
        </div>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Row>
            <Col span={12}>
              <>
                {" "}
                <Button
                  type='text'
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: "16px",
                    width: 64,
                    height: 64,
                  }}
                />
                <span style={{ fontSize: "22px", fontWeight: 500, fontFamily: "Quicksand" }} className='page-title'>
                  {newString && newString.replace("-", " ").toUpperCase()}
                </span>
              </>
            </Col>
            <Col span={12}>
              <div style={{ justifyContent: "flex-end" }}></div>
              <div style={{ float: "right", padding: "10px", marginRight: "80px" }}>
                <span>
                  {" "}
                  <Button className='btn btn mr-4  text-white m-2' shape='circle' icon={<BellOutlined className='text-dark' />} style={{ background: "#F8F8F8", color: "white" }}></Button>
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement='bottom'
                  >
                    <Button
                      className='btn btn mr-4  text-white m-2'
                      style={{ background: " #9B6CFF", fontFamily: "Quicksand" }}
                      shape='circle'
                      icon={<div className='p-1'>{localStorage.getItem("user") && localStorage.getItem("user").toUpperCase().slice(0, 2)}</div>}
                      classNames='bg-dark'
                    ></Button>
                  </Dropdown>
                  <span className='p-1 fw-bold' style={{ fontFamily: "Quicksand" }}>
                    Hi, {localStorage.getItem("user")}
                  </span>
                </span>
              </div>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "90vh",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Content className='content-ant'>{children}</Content>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Sidenav;
