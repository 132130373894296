import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table, Row, Col, Pagination, Modal, Button, Image, Space, Tooltip, Tag, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import ViewCustomized from "../../assets/images/icons/ViewCustomized.png";
import { showFilterModal, hideFilterModal } from "../../redux/filterModal";
import FilterModal from ".././FilterModal";
import StackedBarChart from "./Bar";
import ColumnWithData from "./ColumnWithData";
import BarStackType from "./BarStackType";
import { useLocation } from "react-router-dom";
import { URL } from "../Helper/MainURL";
import { DownloadOutlined } from "@ant-design/icons";
import PapaParse from "papaparse";
import PromoByBrandChart from "./PromoByBrandChart";

const Label = ["", "All promo", "Promo by brand","Promo by Brand/Channel", "Promo by type, %", "Promo by channel, %",];

const Promotions = (props) => {
  const location = useLocation();

  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const filterValues = useSelector((state) => {
    return state.filterValue;
  });
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || 1);
  const [promoData, setPromoData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [modalflag, setModalFlag] = useState(false);
  const [img, setimg] = useState("");
  const [ImageName, setImageName] = useState("");
  const [loader5, setloader5] = useState(false);
  const [calApi, setCalApi] = useState(0);
  const [sorter, setSorter] = useState({ columnKey: "Title", order: "ascend" });
  const { City, SourceType, Timescales, TimescalesTrend, MarketSegment, CompetitiveSet, Category, PromoType, PromoType2, PromoTypeLength, TimePeriodPromo, TimescalesWeekly } = filterValues;
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(1);
    getPromotionsData();
  }, [calApi]);
  useEffect(() => {
    getPromotionsData();
  }, [currentPage, sorter, activeTab]);

  const showModal = () => {
    dispatch(showFilterModal());
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };

  const onChangeTab = (e) => {
    setActiveTab(e);
    setSorter({ columnKey: "Title", order: "ascend" });
    return;
  };
  const getPromotionsData = async () => {
    setloader5(true);
    let filterNew = JSON.parse(JSON.stringify(filterValues));
    let payLoad = {
      page_number: currentPage,
      filters: {
        Timescales: activeTab == 1 ? filterNew.TimescalesTrend : activeTab != 1 && filterNew.TimePeriodPromo.includes("Monthly") ? filterNew.Timescales : filterNew.TimescalesWeekly,
        Market_Segment: filterNew.MarketSegment,
        Competitive_Set: filterNew.CompetitiveSet,
        Category: filterNew.Category,
        Source_Type: filterNew.SourceType,
        Promo_Type2: filterNew.PromoType2,
        Promo_Type: filterNew.PromoType,
        City: filterNew.City && filterNew.City.length ? [filterNew.City[0]] : ['All'],
        PromoLength: filterNew.PromoTypeLength,
      },
      TimePeriodPromo: filterValues.TimePeriodPromo,
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      line_dashboard: activeTab - 1,
    };
    let payLoad2 = {
      page_number: currentPage,
      filters: {
        Timescales: activeTab == 1 ? filterNew.TimescalesTrend : activeTab != 1 && filterNew.TimePeriodPromo.includes("Monthly") ? filterNew.Timescales : filterNew.TimescalesWeekly,
        Market_Segment: filterNew.MarketSegment,
        Competitive_Set: filterNew.CompetitiveSet,
        Category: filterNew.Category,
        Source_Type: filterNew.SourceType,
        Promo_Type2: filterNew.PromoType2,
        Promo_Type: filterNew.PromoType,
      },
      TimePeriodPromo: filterValues.TimePeriodPromo,
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      line_dashboard: activeTab - 1,
    };
    let payLoad3 = {
      page_number: currentPage,
      filters: {
        Timescales: activeTab == 1 ? filterNew.TimescalesTrend : activeTab != 1 && filterNew.TimePeriodPromo.includes("Monthly") ? filterNew.Timescales : filterNew.TimescalesWeekly,
        Market_Segment: filterNew.MarketSegment,
        Competitive_Set: filterNew.CompetitiveSet,
        Source_Type: filterNew.SourceType,
      },
      TimePeriodPromo: filterValues.TimePeriodPromo,
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      line_dashboard: activeTab - 1,
    };
    let payLoad4 = {
      page_number: currentPage,
      filters: {
        Timescales: activeTab == 1 ? filterNew.TimescalesTrend : activeTab != 1 && filterNew.TimePeriodPromo.includes("Monthly") ? filterNew.Timescales : filterNew.TimescalesWeekly,
        Market_Segment: filterNew.MarketSegment,
        Competitive_Set: filterNew.CompetitiveSet,
      },
      TimePeriodPromo: filterValues.TimePeriodPromo,
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      line_dashboard: activeTab - 1,
    };
    let payLoad5 = {
      page_number: currentPage,
      filters: {
        Timescales: activeTab == 1 ? filterNew.TimescalesTrend : activeTab != 1 && filterNew.TimePeriodPromo.includes("Monthly") ? filterNew.Timescales : filterNew.TimescalesWeekly,
        Market_Segment: filterNew.MarketSegment,
        Competitive_Set: filterNew.CompetitiveSet,
        // Source_Type: filterNew.SourceType,
        Promo_Type2: filterNew.PromoType2,
        Promo_Type: filterNew.PromoType,
        City: ["All"],
        Category: filterNew.Category,

       },
      TimePeriodPromo: filterValues.TimePeriodPromo,
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
      line_dashboard: activeTab - 1,
    };

    console.log("Payload5::::::::",payLoad5)

    const randomParam = Math.random().toString(36).substring(7);
    await fetch(`${URL}/${activeTab == 1 ? "promotion" : "promotion-line"}/?${randomParam}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(activeTab == 1 ? payLoad : activeTab == 2 ? payLoad2 : activeTab == 3 ? payLoad5 : activeTab == 4 ? payLoad3 : payLoad4),
    })
      .then(function (response) {
        response.json().then(function (data) {
          setPromoData(data?.data);
          setTotalPage(data?.total_count);
          setData(data);
          setloader5(false);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const columns = [
    {
      title: "Chain",
      dataIndex: "Chain",
      key: "Chain",
      sorter: true,
      fixed: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      width: 200,
      fixed: true,
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Promo Type",
      dataIndex: "PromoType",
      key: "PromoType",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Promo Type 2 ",
      dataIndex: "PromoType2",
      key: "PromoType2",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Category ",
      dataIndex: "Category",
      key: "Category",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Discription",
      dataIndex: "Description",
      key: "Description",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Picture",
      dataIndex: "Image",
      key: "Image",
      render: (value, records) => {
        if (!value || value == "0.00") {
          return (
            <img
              src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/promo/${value}`}
              width='80'
              height='80'
              onClick={(e) => {
                setModalFlag(true);
                setimg(value);
                setImageName(records.Title);
              }}
            />
          );
        }
        return (
          <img
            src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/promo/${value}`}
            width='80'
            height='80'
            onClick={(e) => {
              setModalFlag(true);
              setimg(value);
              setImageName(records.Title);
            }}
          />
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
      sorter: true,
      render: (text) => moment(text).format("DD-MMM-YY"),
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "EndDate",
      sorter: true,
      render: (text) => moment(text).format("DD-MMM-YY"),
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Promo Length",
      dataIndex: "PromoLength",
      key: "PromoLength",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
  ];

  const getDownloadData = (data) => {
   
    downloadcsv(data);
  };
  const downloadcsv = (data) => {
   
    let LastLine1 = { Category: "Copyright 2024.   Meaningful Vision. All Rights Reserved. Proprietary and Confidential" };
    let LastLine2 = { Category: "Property of Meaningful Vision and its Affiliates. Licensed for Use by Meaningful Vision Clients Only." };

    let csvData = [];

    if (activeTab == 4 || activeTab == 3 || activeTab == 5) {
      data.promotype.push(LastLine1, LastLine2); 

      const csvData = [];

      const products = data.Channels;
      const categories = Object.keys(data.promotype[0]);

      categories.forEach((category) => {
        const row = { Category: category };
        data.promotype[0][category].forEach((value, index) => {
          row[products[index]] = value;
        });
       
        csvData.push(row);
      });

      csvData.push(LastLine1,LastLine2); 
      

      var csv = PapaParse.unparse(csvData);
    } else {
      data.data.push(LastLine1, LastLine2);
      csvData = data.data;
      csvData.push(LastLine1, LastLine2); 

      var csv = PapaParse.unparse(csvData);
    }

    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvURL = window.URL.createObjectURL(csvBlob);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `${Label[activeTab]}.csv`);
    tempLink.click();
  };

  return (
    <>
      <Row className='mb-2'>
        <Col span={6} className='p-1'>
          <span className='bg-white text-dark p-2 rounded'>
            <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
              {Label[activeTab]}
            </span>{" "}
            <span style={{ color: "#1DB9DE" }} className='fw-bold'>
              {" "}
              |
            </span>{" "}
            <span className='page-header-table' style={{ fontFamily: "Quicksand" }}>
              {activeTab == 1 ? "Table" : "Chart"}
            </span>
          </span>
        </Col>
        <Col span={14} className='text-center'></Col>
        <Col span={4} className='p-1 text-center'>
          <Button onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
            Filters
          </Button>{" "}
          <Button
            block
            className='mb-4 export-button '
            icon={<DownloadOutlined />}
            size={"large"}
            // loading={loadingExport}
            onClick={() => {
              getDownloadData(data);
            }}
          ></Button>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Space size={[0, "small"]} wrap>
          {activeTab != 1 && (
            <Tooltip
              placement='bottomLeft'
              title={
                TimePeriodPromo && TimePeriodPromo.length > 0
                  ? TimePeriodPromo.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Time Period:
                {TimePeriodPromo && TimePeriodPromo.length > 0 ? `${TimePeriodPromo[0]}  ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          {activeTab == 1 && (
            <Tooltip placement='bottomLeft' title={TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : " All"}>
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Timescales:
                {TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          {activeTab != 1 && TimePeriodPromo.includes("Monthly") && (
            <Tooltip placement='bottomLeft' title={Timescales && Timescales.length > 0 ? Timescales.join("").substring(0, 10) : " All"}>
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Timescales:
                {Timescales && Timescales.length > 0 ? Timescales.join("").substring(0, 10) : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          {activeTab != 1 && TimePeriodPromo.includes("Weekly") && (
            <Tooltip placement='bottomLeft' title={Timescales && Timescales.length > 0 ? TimescalesWeekly : ""}>
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Timescales:
                {TimescalesWeekly && TimescalesWeekly.length > 0 ? TimescalesWeekly.join("").substring(0, 10) : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          <Tooltip
            placement='bottomLeft'
            title={
              MarketSegment && MarketSegment.length > 0
                ? MarketSegment.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Segment:
              {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              CompetitiveSet && CompetitiveSet.length > 0
                ? CompetitiveSet.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Competitive:
              {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          { activeTab != 4  && activeTab != 5 &&(
            <Tooltip
              placement='bottomLeft'
              title={
                Category && Category.length > 0
                  ? Category.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected "
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Category:
                {Category && Category.length > 0 ? `${Category[0]} ` : " Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}

          {activeTab == 1  && (
            <Tooltip
              placement='bottomLeft'
              title={
                activeTab == 1 && City && City.length > 0 ?  City[0] : City && City.length > 0
                  ? City.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                City:
                {activeTab == 1 && City && City.length > 0 ?  City[0] : City && City.length > 0 ? `${City[0]} ${City?.length > 1 ? City.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}

          {activeTab != 5 && activeTab != 3 &&(
            <Tooltip
              placement='bottomLeft'
              title={
                SourceType && SourceType.length > 0
                  ? SourceType.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Channel:
                {SourceType && SourceType.length > 0 ? `${SourceType[0]} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          {activeTab != 4   && activeTab != 5 && (
            <Tooltip
              placement='bottomLeft'
              title={
                PromoType && PromoType.length > 0
                  ? PromoType.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Promo Type:
                {PromoType && PromoType.length > 0 ? `${PromoType[0]} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          {activeTab != 4 &&  activeTab !=5 && (
            <Tooltip
              placement='bottomLeft'
              title={
                PromoType2 && PromoType2.length > 0
                  ? PromoType2.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Promo Type2:
                {PromoType2 && PromoType2.length > 0 ? `${PromoType2[0]} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
          {activeTab == 1 && (
            <Tooltip
              placement='bottomLeft'
              title={
                PromoTypeLength && PromoTypeLength.length > 0
                  ? PromoTypeLength.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Promo Length:
                {PromoTypeLength && PromoTypeLength.length > 0 ? `${PromoTypeLength[0]} ${PromoTypeLength?.length ? PromoTypeLength.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
          )}
        </Space>
      </Row>

      {activeTab && (
        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          className='activetabcolor'
          style={{ textAlign: "center" }}
          onChange={onChangeTab}
          items={Label.map((_, i) => {
            return {
              label: <div className='text-center'>{Label[i + 1]}</div>,
              key: i + 1,
              children: (
                <>
                  {activeTab == 1 && (
                    <>
                      <Table
                        columns={columns}
                        dataSource={promoData}
                        pagination={false}
                        loading={loader5}
                        scroll={{
                          x: 2000,
                          y: "60vh",
                        }}
                        onChange={(val, filter, sorter, extra) => {
                          setSorter({
                            ...sorter,
                          });
                        }}
                      />
                      <div className='d-flex justify-content-end p-4'>
                        <Pagination
                          current={currentPage}
                          defaultCurrent={currentPage}
                          total={totalPage}
                          pageSize={50}
                          onChange={(e) => {
                            setCurrentPage(e);
                          }}
                          showSizeChanger={false}
                        />
                      </div>
                    </>
                  )}
                  {activeTab == 4 && <StackedBarChart data={data} />}
                  {activeTab == 2 && data && <ColumnWithData promoData={data} setActiveTab={setActiveTab} filterValues1={JSON.parse(JSON.stringify(filterValues))} setCalApi={setCalApi} calApi={calApi} />}
                  {activeTab == 5 && <BarStackType data={data} />}
                  {activeTab == 3 && data && <PromoByBrandChart data={data} promoData={data} setActiveTab={setActiveTab} filterValues1={JSON.parse(JSON.stringify(filterValues))} setCalApi={setCalApi} calApi={calApi} />}

                </>
              ),
            };
          })}
        />
      )}

      <Modal
        open={modalflag}
        justifyContent='center'
        destroyOnClose
        onCancel={(e) => {
          setModalFlag(false);
        }}
        footer={false}
        size='large'
        width={600}
        height={600}
        title={<div className='text-left fs-6'>{ImageName}</div>}
      >
        <div style={{ display: "flex", justifyContent: "center", minHeight: "300px" }}>
          <img src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/promo/${img}`} width='300' height='300px' />
        </div>{" "}
      </Modal>
      {isModalOpen && <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} setCalApi={setCalApi} calApi={calApi} name={"Promo"} activePromo={activeTab} dashboard={"Promotions"} />}
    </>
  );
};

export default Promotions;
